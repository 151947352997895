import React, { useState } from 'react';

const Header = ({ setSelectedCategory, selectedCategory }) => {
    // const [categories] = useState([
    //     { "category_id": 1, "title": "قهوه گرم", "picture": require('../assets/images/service16.jpg') },
    //     { "category_id": 2, "title": "قهوه سرد", "picture": require('../assets/images/service17.jpg') },
    //     { "category_id": 3, "title": "شیک", "picture": require('../assets/images/service18.jpg') },
    //     { "category_id": 4, "title": "گرم نوش", "picture": require('../assets/images/service19.jpg') },
    //     { "category_id": 5, "title": "دمنوش", "picture": require('../assets/images/service20.jpg') },
    // ]);


    const [categories] = useState([
        { "category_id": 1, "title": "سمیه حسینی", "picture": require('../assets/images/service1.png') },
        { "category_id": 2, "title": "مهسا محمدی", "picture": require('../assets/images/service2.png') },
        { "category_id": 3, "title": "مهتاب فیضی", "picture": require('../assets/images/service3.png') },
        { "category_id": 4, "title": "گلی رضایی", "picture": require('../assets/images/service4.png') },
        { "category_id": 5, "title": "فاطمه حسابی", "picture": require('../assets/images/service5.png') }
    ]);

    return (
        <div className='header bg-purple'>
            <div className='d-flex services justify-content-center align-items-center h-100'>

                <div className='d-flex container overflow-overlay pointer'>
                    {categories.map((category, index) => (
                        <div
                            key={index}
                            className={`service bg-inside-purple ${selectedCategory === category.category_id ? 'active' : ''}`} onClick={() => setSelectedCategory(category.category_id)}
                        >
                      <img
                        className="imgS"
                        src={category.picture}
                        alt="logo"
                      />
                            <div className='service-name'>{category.title}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Header;

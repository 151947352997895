import React, { useState } from 'react';

const ServiceList = ({ selectedCategory, selectedServices, setSelectedServices }) => {

    // const [services] = useState([
    //     { "service_id": 1, "category_id": 1, "title": "اسپرسو", "price": 55000, "picture": require('../assets/images/service-list-16.jpg') },
    //     { "service_id": 2, "category_id": 1, "title": "آمریکانو", "price": 55000, "picture": require('../assets/images/service-list-17.jpg') },
    //     { "service_id": 3, "category_id": 1, "title": "کاپوچینو", "price": 75000, "picture": require('../assets/images/service-list-18.jpg') },
    //     { "service_id": 4, "category_id": 1, "title": "لته", "price": 85000, "picture": require('../assets/images/service-list-19.jpg') },



    //     { "service_id": 5, "category_id": 2, "title": "آیس آمریکانو", "price": 55000, "picture": require('../assets/images/service-list-20.jpg') },
    //     { "service_id": 6, "category_id": 2, "title": "آیس لته", "price": 85000, "picture": require('../assets/images/service-list-21.jpg') },
    //     { "service_id": 7, "category_id": 2, "title": "آیس موکا", "price": 100000, "picture": require('../assets/images/service-list-22.jpg') },
    //     { "service_id": 8, "category_id": 2, "title": "آفو گاتو", "price": 80000, "picture": require('../assets/images/service-list-23.jpg') },


    //     { "service_id": 9, "category_id": 3, "title": "نوتلا", "price": 110000, "picture": require('../assets/images/service-list-24.jpg') },
    //     { "service_id": 10, "category_id": 3, "title": "قهوه و بادام زمینی", "price": 130000, "picture": require('../assets/images/service-list-25.jpg') },
    //     { "service_id": 11, "category_id": 3, "title": "توت فرنگی و شکلات", "price": 140000, "picture": require('../assets/images/service-list-26.jpg') },



    //     { "service_id": 12, "category_id": 4, "title": "هات چاکلت", "price": 75000, "picture": require('../assets/images/service-list-27.jpg') },
    //     { "service_id": 13, "category_id": 4, "title": "وایت چاکلت", "price": 75000, "picture": require('../assets/images/service-list-28.jpg') },
    //     { "service_id": 14, "category_id": 4, "title": "ماسالا", "price": 65000, "picture": require('../assets/images/service-list-29.jpg') },

    //     { "service_id": 15, "category_id": 5, "title": "سیب دارچین", "price": 60000, "picture": require('../assets/images/service-list-30.jpg') },
    //     { "service_id": 16, "category_id": 5, "title": "شکلات و نعنا", "price": 110000, "picture": require('../assets/images/service-list-31.jpg') },
    //     { "service_id": 17, "category_id": 5, "title": "لبو ، چای ترش", "price": 130000, "picture": require('../assets/images/service-list-32.jpg') },

    // ]);

    const [services] = useState([
        { "service_id": 1, "category_id": 1, "title": "کاشت مژه", "price": 55000, "picture": require('../assets/images/service-list-3.png') },
        { "service_id": 2, "category_id": 1, "title": "کاشت ناخن", "price": 55000, "picture": require('../assets/images/service-list-1.png') },
        { "service_id": 3, "category_id": 1, "title": "کاشت ابرو", "price": 75000, "picture": require('../assets/images/service-list-2.png') },
        { "service_id": 4, "category_id": 1, "title": "فیبروز", "price": 85000, "picture": require('../assets/images/service-list-4.png') },

        { "service_id": 7, "category_id": 2, "title": "کاشت ابرو", "price": 35000, "picture": require('../assets/images/service-list-2.png') },
        { "service_id": 5, "category_id": 2, "title": "کاشت مژه", "price": 25000, "picture": require('../assets/images/service-list-3.png') },
        { "service_id": 6, "category_id": 2, "title": "کاشت ناخن", "price": 45000, "picture": require('../assets/images/service-list-1.png') },
        { "service_id": 8, "category_id": 2, "title": "فیبروز", "price": 75000, "picture": require('../assets/images/service-list-4.png') },


        { "service_id": 9, "category_id": 3, "title": "کاشت مژه", "price": 45000, "picture": require('../assets/images/service-list-3.png') },
        { "service_id": 10, "category_id": 3, "title": "کاشت ناخن", "price": 55000, "picture": require('../assets/images/service-list-1.png') },
        { "service_id": 11, "category_id": 3, "title": "کاشت ابرو", "price": 45000, "picture": require('../assets/images/service-list-2.png') },
        { "service_id": 12, "category_id": 3, "title": "فیبروز", "price": 45000, "picture": require('../assets/images/service-list-4.png') },


        
        { "service_id": 14, "category_id": 4, "title": "کاشت ناخن", "price": 55000, "picture": require('../assets/images/service-list-1.png') },
        { "service_id": 16, "category_id": 4, "title": "فیبروز", "price": 45000, "picture": require('../assets/images/service-list-4.png') },
        { "service_id": 13, "category_id": 4, "title": "کاشت مژه", "price": 45000, "picture": require('../assets/images/service-list-3.png') },
        { "service_id": 15, "category_id": 4, "title": "کاشت ابرو", "price": 45000, "picture": require('../assets/images/service-list-2.png') },


        { "service_id": 20, "category_id": 5, "title": "فیبروز", "price": 85000, "picture": require('../assets/images/service-list-4.png') },
        { "service_id": 17, "category_id": 5, "title": "کاشت مژه", "price": 65000, "picture": require('../assets/images/service-list-3.png') },
        { "service_id": 18, "category_id": 5, "title": "کاشت ناخن", "price": 75000, "picture": require('../assets/images/service-list-1.png') },
        { "service_id": 19, "category_id": 5, "title": "کاشت ابرو", "price": 43000, "picture": require('../assets/images/service-list-2.png') },


    ]);
    const addToSelectedServices = (service) => {
        const isServiceSelected = selectedServices.some(selectedService => selectedService.service_id === service.service_id);
        if (!isServiceSelected) {
            setSelectedServices(prevSelectedServices => [...prevSelectedServices, service]);
        } else {
            alert('این خدمت قبلاً انتخاب شده است!');
        }
    };

    const filteredServices = services.filter(service => service.category_id === selectedCategory);


    return (
        <div className='service-list boxes'>
            <div className='container flex-wrap'>
                {filteredServices.map((service, index) => (
                    <div className='box' key={index}>
                        <img src={service.picture} alt='img service list' className='img-service' />
                        <div className='d-flex justify-content-space-between align-items-center mt-20 mb-16'>
                            <div className='fs-20 fw-700'>{service.title}</div>
                            <div className='d-flex align-items-center price fs-24 fw-700'>{service.price.toLocaleString()}<div className='text-gray fs-12 mr-3'>تومان</div></div>
                        </div>
                        <button className='service-list-button bg-button-purple pointer' onClick={() => addToSelectedServices(service)}>انتخاب خدمت</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServiceList;